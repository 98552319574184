import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import moment from "moment";
import {fetchCouponMaturitySchedule, fetchSideIncomeProfitLoss} from "../../services/portfolioService";
import Loader from "../../layout/loader";
import {nFormatter, numberWithCommas} from "../../utils/helper";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    selectedDate: string,
    selectedPortfolio: any,
    refresh: any,
    theme: any,
    loading: any,
    setLoading: any,
}

const GainsAndLosses = ({theme, selectedDate, refresh, selectedPortfolio, setLoading, loading}: Props) => {
        const [gainsAndLossesData, setGainsAndLossesData] = useState([]);
        const [graphData, setGraphData] = useState({labels: [], datasets: []});
        useEffect(() => {
            setLoading(true);
            let end_date = moment(selectedDate).format("DD-MM-YYYY");
            let start_date = moment(selectedDate).subtract(11, "months").format("DD-MM-YYYY");
            let clientId: any = sessionStorage.getItem("clientId");
            fetchSideIncomeProfitLoss(clientId, start_date, end_date, selectedPortfolio ? selectedPortfolio.id : "").then((res) => {
                if (res?.length > 0) {
                    setGainsAndLossesData(res);
                    setLoading(false);
                } else {
                    setGainsAndLossesData([]);
                    setLoading(false);
                }
            })
        }, [selectedDate, selectedPortfolio, refresh]);

        useEffect(() => {
            const labels: any = [];

            gainsAndLossesData?.map((data: any) => {
                labels.push([data.monthName?.toLowerCase().substring(0, 3).charAt(0).toUpperCase() + data.monthName?.toLowerCase().substring(0, 3).slice(1), data.year.toString().slice(-2)]);
            })

            const data = {
                labels,
                datasets: [
                    {
                        label: 'Portfolio gains & losses',
                        data: gainsAndLossesData?.map((data: any) => data.realisedIncomePL),
                        backgroundColor: '#e5c66e',
                    },
                ],
            };
            // @ts-ignore
            setGraphData(() => {
                return {
                    ...data,
                };
            });
        }, [gainsAndLossesData]);

        const options: any = {
            responsive: true,
            plugins: {
                datalabels: {
                    formatter: function (value: string) {
                        return nFormatter(value);
                    },
                    anchor: "end",
                    offset: -20,
                    align: "start",
                    crop: false,
                    overflow: 'none',
                    // color: function () {
                    //     return theme ? "#3c3c6c" : "#ffffff";
                    // },
                    // backgroundColor: function (context: any) {
                    //     return context?.dataset?.backgroundColor;
                    // }
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                yAxes: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        color: theme === 1 ? "#3c3c6c" : theme === 2 ? '#4b4b4b' : "#54548f",
                        callback: function (value: any) {
                            return nFormatter(value);
                        }
                    }
                },
                xAxes: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        color: theme === 1 ? "#3c3c6c" : theme === 2 ? '#4b4b4b' : "#54548f",
                        font: {
                            size: 11
                        }
                    }
                },
            },
        };

        return (
            <>
                {/*{loading && <Loader/>}*/}
                <div className="gain-loss">
                    <p className="heading">Portfolio gains & losses</p>
                    {loading ? <p className="loader">Loading...</p>
                        : <div className="bar-chart">
                            {graphData?.labels && (
                                <Bar options={options} data={graphData} width={10}
                                     height={3}/>
                            )}
                        </div>
                    }
                </div>
            </>
        );
    }
;

export default GainsAndLosses;
