import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ArcElement, PointElement, LineElement, Filler,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import moment from "moment";
import {fetchCouponMaturitySchedule} from "../../services/portfolioService";
import {nFormatter} from "../../utils/helper";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    ArcElement,
    PointElement,
    LineElement,
    Legend,
    Filler
);

interface Props {
    selectedDate: string,
    selectedPortfolio: any,
    refresh: any,
    theme: any,
    setLoading: any,
    loading: any,
}

const CouponsAndMaturity = ({theme, selectedDate, refresh, selectedPortfolio, loading, setLoading}: Props) => {
        const [couponsAndMaturity, setCouponsAndMaturity] = useState([]);
        const [graphData, setGraphData] = useState({labels: [], datasets: []});
        useEffect(() => {
            setLoading(true);
            let date = selectedDate ? moment(selectedDate).format("DD-MM-yyyy") : moment().format("DD-MM-yyyy")
            fetchCouponMaturitySchedule(date, true, selectedPortfolio ? selectedPortfolio.id : "").then((res) => {
                if (res?.length > 0) {
                    setCouponsAndMaturity(res);
                    setLoading(false);
                } else {
                    setCouponsAndMaturity([]);
                    setLoading(false);
                }
            })
        }, [selectedDate, selectedPortfolio, refresh]);

        useEffect(() => {
            if(couponsAndMaturity){
                const labels: any = [];

                couponsAndMaturity.sort(function (a: any, b: any) {
                    let splitDate1: string = a.date?.split("-");
                    let splitDate2: string = b.date?.split("-");
                    return new Date(splitDate1[1] + "-" + splitDate1[0] + "-" + splitDate1[2] + "-").getTime() - new Date(splitDate2[1] + "-" + splitDate2[0] + "-" + splitDate2[2] + "-").getTime();
                });

                couponsAndMaturity?.slice(0, 12)?.map((data: any) => {
                    let splitDate: string = data.date?.split("-");
                    labels.push([moment(splitDate[1] + "-" + splitDate[0] + "-" + splitDate[2] + "-")?.format("MMM"), splitDate[2]?.slice(-2)]);
                })

                const data = {
                    labels,
                    datasets: [
                        {
                            label: 'Coupon & maturities',
                            data: couponsAndMaturity?.map((data: any) => data.value),
                            backgroundColor: 'rgb(107,146,179)',
                        },
                    ],
                };
                // @ts-ignore
                setGraphData(() => {
                    return {
                        ...data,
                    };
                });
            }
        }, [couponsAndMaturity]);

        const options: any = {
            responsive: true,
            plugins: {
                datalabels: {
                    formatter: function (value: string) {
                        return nFormatter(value);
                    },
                    anchor: "end",
                    offset: -20,
                    align: "start",
                    crop: false,
                    overflow: 'none',
                    // color: function () {
                    //     return theme === 1 ? "#3c3c6c" : theme === 2 ? '#5b5757' : "#54548f"
                    // },
                    // backgroundColor: function (context: any) {
                    //     return context?.dataset?.backgroundColor;
                    // }
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                yAxes: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        color: theme === 1 ? "#3c3c6c" : theme === 2 ? '#5b5757' : "#54548f",
                        callback: function (value: any) {
                            return nFormatter(value);
                        }
                    }
                },
                xAxes: {
                    stacked: true,
                    grid: {
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        color: theme === 1 ? "#3c3c6c" : theme === 2 ? '#4b4b4b' : "#54548f",
                        font: {
                            size: 11
                        }
                    }
                },
            },
        };

        return (
            <>
                {/*{loading && <Loader/>}*/}
                <div className="coupon-maturity">
                    <p className="heading">Coupon & maturities</p>
                    {loading ? <p className="loader">Loading...</p> :
                        <div className="bar-chart">
                            {graphData?.labels?.length > 0 ? (
                                <Bar options={options} data={graphData} width={10}
                                     height={3}/>
                            ): <p className="nodata">No Data</p>}
                        </div>
                    }
                </div>
            </>
        );
    }
;

export default CouponsAndMaturity;
