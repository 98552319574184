import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import moment from "moment";
import {fetchCouponMaturitySchedule} from "../../services/portfolioService";
import {numberWithCommasAndDecimal} from "../../utils/helper";
import Loader from "../../layout/loader";

interface Props {
    selectedDate: string,
    selectedPortfolio: any,
    refresh: any,
    theme: any,
    loading: any,
    setLoading: any
}

const AccruedIncome = ({theme, selectedDate, refresh, selectedPortfolio, setLoading, loading}: Props) => {
        const ref = useRef<any>(null);
        const [width, setWidth] = useState<any>(0);
        const [accruedIncomeData, setAccruedIncomeData] = useState([]);
        const [barValues, setBarValues] = useState<any>({});

        useLayoutEffect(() => {
            if(barValues?.percentage){
                setWidth(ref?.current.offsetWidth);
            }
        }, [barValues]);

        useEffect(() => {
            let date = selectedDate ? moment(selectedDate).format("DD-MM-yyyy") : moment().format("DD-MM-yyyy")
            fetchCouponMaturitySchedule(date, false, selectedPortfolio ? selectedPortfolio.id : "").then((res) => {
                if (res?.length > 0) {
                    let arr: any = [];
                    res.map((data: any) => {
                        if (data.type === "COUPON") {
                            arr.push(data);
                        }
                    })
                    setAccruedIncomeData(arr);
                    setLoading(false);
                } else {
                    setAccruedIncomeData([]);
                    setLoading(false);
                }
            })
        }, [selectedDate, selectedPortfolio, refresh]);

        useEffect(() => {
            if (accruedIncomeData) {
                let obj: any = {
                    pointValue: 0,
                    finalValue: 0,
                    percentage: 0,
                    currency: "",
                }
                accruedIncomeData?.map((data: any) => {
                    obj.pointValue = parseFloat(data.accruedValue) + parseFloat(obj.pointValue);
                    obj.finalValue = parseFloat(data.value) + parseFloat(obj.finalValue);
                    obj.currency = data.securityCurrency.symbol;
                })
                obj.percentage = (obj.pointValue / obj.finalValue) * 100;
                setBarValues(obj);
            }
        }, [accruedIncomeData]);

        return (
            <>
                <div className="accrued-income">
                    <p className="heading">Accrued Income</p>
                    {loading ? <p className="loader">Loading...</p> : <>
                        <div className="horizontal-bar">
                            <div className="filled-bar" ref={ref} style={{width: barValues?.percentage + "%"}}></div>
                            {width > 0 && (
                                <div className="badge"
                                     style={{left: `calc(${barValues?.percentage?.toFixed(2) + "%"} - ${(width / 2)-16 + "px"})`}}>
                                    {barValues?.currency} {numberWithCommasAndDecimal(barValues?.pointValue)}/{barValues?.percentage ? barValues?.percentage?.toFixed(2) + "%" : "0%"}
                                </div>
                            )}
                            {/*<span className="arrow"></span>*/}
                        </div>
                        <div className="labels">
                            <div>
                                {barValues?.currency}0
                            </div>
                            <div>
                                {barValues?.currency} {numberWithCommasAndDecimal(barValues?.finalValue)}
                            </div>
                        </div>
                        {/*<div className="bullet-points">*/}
                        {/*    <p>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                        {/*    </p>*/}
                        {/*    <p>*/}
                        {/*        Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </>}
                </div>
            </>
        );
    }
;

export default AccruedIncome;
