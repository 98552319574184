import React, {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import {Radar} from 'react-chartjs-2';
import {fetchDashboardAttribute} from "../../services/marketServices";
import moment from "moment";
import { numberWithCommasAndDecimal } from "../../utils/helper";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

interface Props {
    selectedDate: string,
    selectedPortfolio: any,
    refresh: any,
    loading: any,
    setLoading: any,
    selectedClient: any,
    theme: any,
}

const Diversification = ({selectedDate, refresh, selectedPortfolio, loading, setLoading, selectedClient, theme}: Props) => {
        const [diversificationData, setDiversificationData] = useState<any>({});
        const [graphData, setGraphData] = useState<any>({labels: [], datasets: []});
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
                line: {
                    borderWidth: 1
                }
            },
            scales: {
                r: {
                    ticks: {
                        display: false,
                        color: "red"
                    },
                    pointLabels: {
                        color: theme === 1 ? "#3c3c6c" : theme === 2 ? '#5b5757' : "#54548f",
                        font: {
                            size: 12,
                        }
                    },
                    grid: {
                        color: theme === 1 ? "#efefef" : theme === 2 ? '#282828' : "#3c3c6c",
                    },
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            },
            // tooltips: {
            //     callbacks: {
            //         title: function(t: any, d: any) {
            //             console.log(t, d);
            //             return "helllo"
            //         }
            //     }
            // }
        }

        useEffect(() => {
            setLoading(true);
            let clientId: any = sessionStorage.getItem("clientId");
            fetchDashboardAttribute(clientId, selectedDate ? moment(selectedDate).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"), selectedPortfolio ? selectedPortfolio.id : "").then(res => {
                setDiversificationData(res);
                setLoading(false);
            });
        }, [refresh, selectedPortfolio, selectedDate]);

        useEffect(()=>{
            if(diversificationData?.standardDeviation || diversificationData?.benchmarkAttribute?.standardDeviation){
                let portfolio = [diversificationData.standardDeviation, diversificationData.mduration, diversificationData.sharpeRatio, diversificationData.averageGain, diversificationData.averageLoss, diversificationData.gainToLossRatio];
                let benchmark = [diversificationData.benchmarkAttribute?.standardDeviation, diversificationData.benchmarkAttribute?.mduration, diversificationData.benchmarkAttribute?.sharpeRatio, diversificationData.benchmarkAttribute?.averageGain, diversificationData.benchmarkAttribute?.averageLoss, diversificationData.benchmarkAttribute?.gainToLossRatio];
                const data = {
                    labels: ["Volatility", "Duration", "Sharpe Ratio ", "Average Gain (%)", "Average Loss (%)", "Gain/Loss Ratio",],
                    datasets: [{
                        label: selectedPortfolio?.name ? selectedPortfolio?.name : selectedClient?.name,
                        backgroundColor: "rgba(255,206,86,0.20)",
                        pointBackgroundColor: "rgba(255,206,86,1)",
                        borderColor: "rgba(218,218,218,0.45)",
                        borderWidth: 1,
                        hoverPointBackgroundColor: "#fff",
                        data: portfolio?.map((value) => numberWithCommasAndDecimal(value, 2)),
                    }, {
                        label: "Benchmark",
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        pointBackgroundColor: 'rgba(153, 102, 255, 1)',
                        borderColor: "rgba(218,218,218,0.45)",
                        borderWidth: 1,
                        hoverPointBackgroundColor: "#fff",
                        data: benchmark?.map((value) => numberWithCommasAndDecimal(value, 2)),
                    }]
                }
                setGraphData(data);
            }
        },[diversificationData]);

        return (
            <>
                <div className="diversification">
                    <p className="heading">Metrics</p>
                    {loading ? <p className="loader">Loading...</p> : (
                      <>
                          {diversificationData?.standardDeviation || diversificationData?.benchmarkAttribute?.standardDeviation ? (
                            <>
                                <div className="radar-chart">
                                    <Radar data={graphData} options={options}/>
                                </div>
                                <div className="wrapper">
                                    <div className="labels">
                                        <div className="metrics">
                                            <p className="main-label">Metrics</p>
                                            {graphData?.labels?.map((label: any, index: number) =>{
                                                return(
                                                    <p className="label-div" key={index}>{label}</p>
                                                )
                                            })}
                                        </div>
                                        <div className="dataset-one">
                                            <p className="dataset-one-main-label">{selectedPortfolio?.name ? selectedPortfolio?.name : selectedClient?.name}</p>
                                            {graphData?.datasets[0]?.data?.map((value: any, index: number) => {
                                                return (<p className="dataset-one-label" key={index}>{value}</p>)
                                            })}
                                        </div>
                                        <div className="dataset-two">
                                            <p className="dataset-two-main-label">Benchmark</p>
                                            {graphData?.datasets[1]?.data?.map((value: any, index: number) => {
                                                return (<p className="dataset-two-label" key={index}>{value}</p>)
                                            })}
                                        </div>
                                        {/*<div className="left">*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Risk</div>*/}
                                        {/*        <div className="value">{diversificationData.standardDeviation ? numberWithCommasAndDecimal(diversificationData.standardDeviation) : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Duration</div>*/}
                                        {/*        <div className="value">{diversificationData.mduration ? numberWithCommasAndDecimal(diversificationData.mduration) : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Standard Deviation</div>*/}
                                        {/*        <div className="value">{diversificationData.ytdReturn ? numberWithCommasAndDecimal(diversificationData.standardDeviation) : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="right">*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Sharpe Ratio</div>*/}
                                        {/*        <div className="value">{diversificationData.sharpeRatio ? numberWithCommasAndDecimal(diversificationData.sharpeRatio) : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Average Gain</div>*/}
                                        {/*        <div className="value">{diversificationData.averageGain ? numberWithCommasAndDecimal(diversificationData.averageGain)+"%" : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="labels-content">*/}
                                        {/*        <div className="main-label">Average Loss</div>*/}
                                        {/*        <div className="value">{diversificationData.averageLoss ? numberWithCommasAndDecimal(diversificationData.averageLoss)+"%" : "0.00"}</div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </>
                          ): <p className="nodata">No Data</p>}
                      </>
                    )}
                </div>
            </>
        );
    }
;

export default Diversification;
