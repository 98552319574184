import React, {useEffect, useState} from "react";
import DepositIcon from "../../assets/minified-svg/depositIcon.svg";
import Withdrawal from "../../assets/minified-svg/withdrawal.svg";
import RealizedIncome from "../../assets/minified-svg/realizedIncome.svg";
import UnRealizedIncome from "../../assets/minified-svg/unrealizedIncome.svg";
import TotalIncome from "../../assets/minified-svg/totalIncome.svg";
import Receivables from "../../assets/minified-svg/receivables.svg";
import NetAssetValue from "../../assets/minified-svg/newAssetValue.svg";
import {numberWithCommasAndDecimal} from "../../utils/helper";

interface Props {
    summaryData: any,
    loading: any
}

const PortfolioSummary = ({summaryData, loading}: Props) => {
        return (
            <>
                <div className="portfolio_summary">
                    <p className="heading">Portfolio Summary</p>
                    {loading
                        ? <p className="loader">loading...</p>
                        : <div className="card-wrapper fade-in">
                            <div className="card">
                                <div className="left">
                                    <NetAssetValue/>
                                    <div className="innerHeading">Net Asset Value</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.nav)}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.difference?.nav)}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <DepositIcon/>
                                    <div className="innerHeading">Total Deposit</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.deposits)}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.difference?.deposits)}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <Withdrawal/>
                                    <div className="innerHeading">Withdrawal</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.fundWithdrawal)}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.difference?.fundWithdrawal)}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <RealizedIncome/>
                                    <div className="innerHeading">Realized Income</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(parseFloat(summaryData?.realizedPL) + parseFloat(summaryData?.realisedIncome))}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(parseFloat(summaryData?.difference?.realizedPL) + parseFloat(summaryData?.difference?.realisedIncome))}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <UnRealizedIncome/>
                                    <div className="innerHeading">UnRealized Income</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.unRealizedGL)}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.difference?.unRealizedGL)}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <TotalIncome/>
                                    <div className="innerHeading">Cumulative Return</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{numberWithCommasAndDecimal(summaryData?.cumulativeReturnPercentage)}%</div>
                                    <div
                                        className="deposit">{numberWithCommasAndDecimal(summaryData?.difference?.cumulativeReturnPercentage)}%</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="left">
                                    <Receivables/>
                                    <div
                                        className="innerHeading">{summaryData?.ledgerBalance < 0 ? "Payables" : "Receivables"}</div>
                                </div>
                                <div className="right">
                                    <div
                                        className="amount">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.ledgerBalance)}</div>
                                    <div
                                        className="deposit">{summaryData?.currency?.symbol} {numberWithCommasAndDecimal(summaryData?.difference?.ledgerBalance)}</div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
;

export default PortfolioSummary;
