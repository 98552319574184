import React, {useEffect, useState} from 'react';
import {useClient} from '../components/clientselection/useClient';
import "../styles/_dashboard.scss";
import "../styles/_portfolio.scss";
import PortfolioSummary from "../components/dashboard_component/PortfolioSummary";
import Diversification from "../components/dashboard_component/Diversification";
import AccruedIncome from "../components/dashboard_component/AccruedIncome";
import CouponsAndMaturity from "../components/dashboard_component/CouponsAndMaturity";
import GainsAndLosses from "../components/dashboard_component/GainsAndLosses";
import DashboardTables from "../components/dashboard_component/DashboardTables";
import Loader from '../layout/loader';
import PositionNewPageCompo from "../components/positionNewPageCompo";

interface DashboardProps {
    portfolio?: any,
    selectedDate: any
    theme: any,
    setPortfolio: any,
    setSelectedDate: any,
    refresh: any,
    summaryData: any,
    availablePortfolios: any
}

const Index = ({
                   theme,
                   portfolio,
                   selectedDate,
                   refresh,
                   summaryData,
               }: DashboardProps) => {
    const {selectedClient} = useClient();
    const [loading, setLoading] = useState(false);

    return (
        <>
            <div key={selectedClient?.id} className="dashboard">
                {loading && <Loader/>}
                <div className="dashboard-layout">
                    <PortfolioSummary summaryData={summaryData} loading={loading}/>
                    <Diversification selectedPortfolio={portfolio} theme={theme} selectedClient={selectedClient}
                                     setLoading={setLoading} loading={loading} selectedDate={selectedDate}
                                     refresh={refresh}/>
                    <div className="charts-section">
                        <AccruedIncome selectedPortfolio={portfolio} setLoading={setLoading} loading={loading}
                                       theme={theme}
                                       selectedDate={selectedDate} refresh={refresh}/>
                        <CouponsAndMaturity selectedPortfolio={portfolio} setLoading={setLoading} loading={loading}
                                            theme={theme} selectedDate={selectedDate} refresh={refresh}/>
                        <GainsAndLosses selectedPortfolio={portfolio} theme={theme} setLoading={setLoading}
                                        loading={loading} selectedDate={selectedDate} refresh={refresh}/>
                    </div>
                </div>
                <DashboardTables selectedPortfolio={portfolio} theme={theme} setLoading={setLoading} loading={loading}
                                 selectedDate={selectedDate} refresh={refresh} selectedClient={selectedClient}/>
            </div>
        </>
    )
}

export default Index;

