import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Tab, Tabs} from "@mui/material";
import "../../styles/_analysis.scss";
import MarketTable from "../common_compoenent/MarketTable";
import { fetchSidePortfolioSummaryAll } from "../../services/portfolioService";
import moment from "moment";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Props {
    selectedDate: string,
    selectedClient: any,
    selectedPortfolio: any,
    refresh: any,
    theme: any,
    loading: any,
    setLoading: any
}

const DashboardTables = ({theme, selectedDate, refresh, selectedPortfolio, selectedClient, loading, setLoading}: Props) => {
    const buttons = ["Wallet"];
    const [tableData, setTableData] = React.useState([]);
    const [activeCategory, setActiveCategory] = useState(0);
    const [currencySymbol, setCurrencySymbol] = React.useState("");
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveCategory(newValue);
    };

    useEffect(() => {
        if (selectedPortfolio) {
            setCurrencySymbol(selectedPortfolio?.currencySymbol);
        } else if (selectedClient) {
            setCurrencySymbol(selectedClient?.currencySymbol);
        }
    }, [selectedPortfolio]);

    useEffect(()=>{
        setLoading(true);
        let clientId: any = sessionStorage.getItem("clientId");
        fetchSidePortfolioSummaryAll(clientId, selectedDate ? moment(selectedDate).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY"))
            .then(res => {
                setLoading(false);
                setTableData(res);
            }).catch((e) => {
            setLoading(false);
        });
    },[refresh, selectedDate]);


    const investment_columns = [
        {
            title: "Portfolio",
            key: "bsSecurityMediumId",
            sortable: false,
            type: "text",
            medium: true,
            align: "left",
        },
        {
            title: "Type",
            key: "issuer",
            sortable: false,
            type: "text",
            small: true,
            align: "left",
        },
        {
            title: "Risk",
            key: "assetClass",
            sortable: false,
            type: "text",
            medium: true,
            align: "left",
        },
        {
            title: "Return",
            key: "quantity",
            sortable: false,
            type: "quantity",
            medium: true,
            align: "left",
        },
        {
            title: "Horizon",
            key: "type",
            sortable: false,
            type: "text",
            medium: true,
            align: "left",
        },
        {
            title: "Investment",
            key: "price",
            sortable: false,
            type: "2DPPrice",
            large: true,
            align: "left",
        },{
            title: "Target",
            key: "price",
            sortable: false,
            type: "2DPPrice",
            large: true,
            align: "left",
        },
    ]
    const wallet_columns = [
        {
            title: "Portfolio",
            key: "portfolioName",
            sortable: false,
            type: "text",
            large: true,
            align: "left",
        },

        {
            title: "Deposit",
            key: "deposits",
            sortable: false,
            type: "2DPPrice",
            // align: "left",
            large: true,
        },
        {
            title: "Withdrawal",
            key: "fundWithdrawal",
            sortable: false,
            type: "2DPPrice",
            large: true,
        },
        {
            title: "Receivables/Payables",
            key: "ledgerBalance",
            sortable: false,
            type: "2DPPrice",
            large: true,
        },
        {
            title: "Cash Balance",
            key: "cashBalance",
            sortable: false,
            type: "2DPPrice",
            large: true,
        },
        {
            title: "Available cash balance",
            key: "calculateAvailableCash",
            sortable: false,
            type: "2DPPrice",
            large: true,
        },
        {
            title: "Action",
            key: "walletTabButtons",
            sortable: false,
            type: "walletTabButtons",
            large: true,
            align: "left",
        }
    ]

    return (
            <>
                {/*{loading && <Loader/>}*/}
                <div className="dashboard-tables">
                    <div className="analysis">
                        {/*<h3 className="analysis__label">Trading & MarketsLogin</h3>*/}
                        <div className="analysis__tabs">
                            <Box sx={{width: '100%', typography: 'body1'}}>
                                <Tabs className="analysis__tabs-indicator" variant="scrollable"
                                      scrollButtons="auto" value={activeCategory} onChange={handleChange}>
                                    {buttons.map((btn, i) => {
                                        return (
                                            <Tab className={`analysis__tabs-tab  ${i === activeCategory ? "active" : ""}`}
                                                 label={btn} {...a11yProps(i)} disabled={btn === "International"}/>
                                        );
                                    })}
                                </Tabs>
                            </Box>
                        </div>
                    </div>
                    <div className="dashboard-tables-main">
                        {loading ? <p className="loader">Loading...</p>
                            : <div className={`${!loading && "fade-in"}`}>
                                {/*{activeCategory===0 && <MarketTable columns={investment_columns} data={[]} theme={theme} isChart={true} currencySymbol={currencySymbol} selectedDate={selectedDate}/>}*/}
                                {activeCategory===0 && <MarketTable columns={wallet_columns} data={tableData} theme={theme} isChart={true} selectedPortfolio={selectedPortfolio} currencySymbol={currencySymbol} selectedDate={selectedDate}/>}
                            </div>}
                    </div>
                </div>
            </>
        );
    }
;

export default DashboardTables;
